import React from 'react';
import { BInput } from 'common/components';
import { Form, Radio } from 'antd';

const Client = ({ showInputs }) => {
  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 10 }}>
      <BInput disabled isRequired className="form-add-user-input" name="soldTo" label="Sold to" />

      <BInput disabled isRequired className="form-add-user-input" name="name" label="Nombre" />

      <BInput
        disabled
        isRequired
        className="form-add-user-input"
        name="timeLimit"
        label="Plazo en el sistema"
      />

      <BInput
        disabled
        isRequired
        className="form-add-user-input"
        name="creditLimit"
        label="Límite de crédito"
      />
      <BInput
        disabled
        isRequired
        className="form-add-user-input"
        name="presence"
        label="Presencia:"
      />
      <BInput disabled className="form-add-user-input" name="type" label="Tipo:" />

      <BInput
        disabled
        isRequired
        className="form-add-user-input"
        name="line"
        label="Línea de negocio:"
      />

      <BInput disabled className="form-add-user-input" name="segmentation" label="Segmentación:" />

      <BInput
        disabled
        isRequired
        className="form-add-user-input"
        name="tradeAgreementRequired"
        label="Requiere PDF de acuerdo comercial"
      />

      {showInputs.agreementRequirement && (
        <Form.Item name="agreementRequirement" label="*Tipo de acuerdo comercial">
          <Radio.Group disabled style={{ display: 'flex', flexDirection: 'row' }}>
            <Radio value={1}>Sell In</Radio>
            <Radio value={2}>Sell Out</Radio>
            <Radio value={3}>Ambos</Radio>
          </Radio.Group>
        </Form.Item>
      )}

      {showInputs.imageFile && (
        <BInput
          disabled
          isRequired
          className="form-add-user-input"
          name="imageFile"
          label="Imagen para archivo PDF del acuerdo comercial:"
        />
      )}

      <BInput
        disabled
        isRequired
        className="form-add-user-input"
        name="address"
        label="Dirección:"
      />

      <BInput
        disabled
        isRequired
        className="form-add-user-input"
        name="company"
        label="Compañía:"
      />
    </div>
  );
};

export default Client;
