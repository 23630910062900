/* eslint-disable array-callback-return */
import { openNotification, toBase64 } from 'common';
export const validaForm = (numResta, form, value, setIsActiveRegister) => {
  const allValues = form.getFieldsValue();
  let countRV = 0;
  if (value.length > 0) {
    value.map((d) => {
      if (
        allValues[`territoryId${d}`] !== undefined &&
        allValues[`respresentativeId${d}`] !== undefined
      ) {
        countRV++;
      }
    });
  }
  const {
    soldTo,
    tradeAgreement,
    name,
    termTime,
    creditLimit,
    placementId,
    lineId,
    address,
    companyId,
    territoryId,
    respresentativeId,
    ownerName,
    fullNameClient1,
    positionClient1,
    fullName1,
    position1,
    fullName2,
    position2,
    fullName,
    departament,
    email,
    totalInvoicingPreviousYear,
    billingCurrency,
    tradeAgreementCurrency,
    pogId,
    agreementRequirement,
    imageFile,
    segmentationId,
  } = allValues;

  if (tradeAgreement === true) {
    let arraValidate = [
      soldTo,
      name,
      termTime,
      creditLimit,
      placementId,
      lineId,
      address,
      companyId,
      territoryId,
      respresentativeId,
      ownerName,
      fullNameClient1,
      positionClient1,
      fullName1,
      position1,
      fullName2,
      position2,
      fullName,
      departament,
      email,
      totalInvoicingPreviousYear,
      billingCurrency,
      tradeAgreementCurrency,
      pogId,
      agreementRequirement,
    ];

    let countValida = 0;
    let validateNumber = 25;
    if (segmentationId === 1 || segmentationId === 2) {
      arraValidate.push(imageFile);
      validateNumber = 26;
    }
    arraValidate.map((d) => {
      if (typeof d == 'string' && d === '') {
        d = null;
      }
      if (d !== undefined && d !== null) {
        countValida++;
      }
    });

    if (countValida === validateNumber && countRV === value.length - numResta) {
      setIsActiveRegister(false);
    } else {
      setIsActiveRegister(true);
    }
  } else {
    let arraValidate2 = [
      soldTo,
      name,
      termTime,
      creditLimit,
      placementId,
      lineId,
      address,
      companyId,
      territoryId,
      respresentativeId,
      ownerName,
      fullNameClient1,
      positionClient1,
      fullName1,
      position1,
      fullName2,
      position2,
      fullName,
      departament,
      email,
      totalInvoicingPreviousYear,
    ];
    let countValida2 = 0;
    arraValidate2.map((d, index) => {
      if (typeof d == 'string' && d === '') {
        d = null;
      }
      if (d !== undefined && d !== null) {
        countValida2++;
      }
    });

    if (countValida2 === 21 && countRV === value.length - numResta) {
      setIsActiveRegister(false);
    } else {
      setIsActiveRegister(true);
    }
  }
};

export const onSubmit = async (history, form, value, clientId, registerClient) => {
  const allValues = form.getFieldsValue();
  const onSuccess = (message) => {
    openNotification('success', message);
    setTimeout(() => {
      history.push('/catalog/clients');
    }, 200);
  };
  const errorMessage = (message) => {
    openNotification('error', message);
  };
  const validaPerson = (name, position) => {
    let valor;
    if (name !== undefined && position !== undefined && name !== '' && position !== '') {
      valor = true;
    } else {
      valor = false;
    }
    return valor;
  };
  let parameters = {
    soldTo: allValues.soldTo,
    name: allValues.name,
    termTime: allValues.termTime,
    creditLimit: parseFloat(allValues.creditLimit),
    placementId: allValues.placementId,
    typeId: allValues.typeId,
    lineId: allValues.lineId,
    segmentationId: allValues.segmentationId,
    tradeAgreement: allValues.tradeAgreement,
    address: allValues.address,
    companyId: allValues.companyId,
    representatives: [
      { territoryId: allValues.territoryId, respresentativeId: allValues.respresentativeId },
    ],
    ownerName: allValues.ownerName,
    clientSigningPerson1: {
      fullName: allValues.fullNameClient1,
      position: allValues.positionClient1,
    },
    clientSigningPerson2: validaPerson(allValues.fullNameClient2, allValues.positionClient2)
      ? { fullName: allValues.fullNameClient2, position: allValues.positionClient2 }
      : null,
    signingPerson1: { fullName: allValues.fullName1, position: allValues.position1 },
    signingPerson2: { fullName: allValues.fullName2, position: allValues.position2 },
    signingPerson3: validaPerson(allValues.fullName3, allValues.position3)
      ? { fullName: allValues.fullName3, position: allValues.position3 }
      : null,
    signingPerson4: validaPerson(allValues.fullName4, allValues.position4)
      ? { fullName: allValues.fullName4, position: allValues.position4 }
      : null,

    contact: {
      fullName: allValues.fullName,
      departament: allValues.departament,
      email: allValues.email,
    },
    branches: allValues.branches,
    clients: allValues.clients,
    salesForce: allValues.salesForce,
    technicalRepresentatives: allValues.technicalRepresentatives,
    totalInvoicingPreviousYear: parseFloat(allValues.totalInvoicingPreviousYear),
    billingCurrency: allValues.billingCurrency,
    tradeAgreementCurrency: allValues.tradeAgreementCurrency,
    icotermType: allValues.icotermType,
    pogId: allValues.pogId,
    /* contactAccountStatement: {
      emailAS1: allValues.email1 === '' ? undefined : allValues.email1,
      emailAS2: allValues.email2 === '' ? undefined : allValues.email2,
      emailAS3: allValues.email3 === '' ? undefined : allValues.email3,
      emailAS4: allValues.email4 === '' ? undefined : allValues.email4,
      emailAS5: allValues.email5 === '' ? undefined : allValues.email5,
      nameAS1: allValues.contact1 === '' ? undefined : allValues.contact1,
      nameAS2: allValues.contact2 === '' ? undefined : allValues.contact2,
      nameAS3: allValues.contact3 === '' ? undefined : allValues.contact3,
      nameAS4: allValues.contact4 === '' ? undefined : allValues.contact4,
      nameAS5: allValues.contact5 === '' ? undefined : allValues.contact5,
    }, */
  };

  if (allValues.tradeAgreement === true) {
    let imgBase64 = undefined;
    let fileName = undefined;
    if (allValues.imageFile) {
      if (allValues.imageFile.fileData) {
        imgBase64 = allValues.imageFile.fileData;
        fileName = allValues.imageFile.fileName;
      } else {
        imgBase64 = await toBase64(allValues.imageFile).then((res) => {
          return res;
        });
        imgBase64 = imgBase64.replace(/^data:image\/[a-z]+;base64,/, '');
        fileName = allValues.imageFile.name;
      }
    }
    parameters = {
      ...parameters,
      agreementRequirement: allValues.agreementRequirement,
      imageFile: imgBase64,
      fileName: fileName,
    };
  } else {
    // parameters = {
    //   ...parameters,
    //   agreementRequirement: 0,
    // };
  }

  if (value.length > 0) {
    value.map((d) => {
      if (
        allValues[`territoryId${d}`] !== undefined &&
        allValues[`respresentativeId${d}`] !== undefined
      ) {
        parameters.representatives.push({
          territoryId: allValues[`territoryId${d}`],
          respresentativeId: allValues[`respresentativeId${d}`],
        });
      }
    });
  }

  registerClient({ parameters, onSuccess, errorMessage, clientId: clientId });
  for (let i = 0; i < 20; i++) {
    localStorage.removeItem(i);
  }
};
