import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { actionTradeEnum } from 'Domains/TradeAgreements/models/enums';
import DownloadReport from 'Domains/TradeAgreements/components/LayoutTradeAgreements/DownloadReport';
import BButton from 'common/components/BButton';
import { RoleAccessControl } from 'Domains/TradeAgreements/common/accessControllers';
import { salesChallengeStatus, roleIdEnum } from 'common/models/enums';
import { setAgreementType } from 'Domains/TradeAgreements/redux/actions/infoForAgreement';
import { generateTradeAgreementRequest } from '../../redux/actions/agreement';
import SendToValidate from 'Domains/TradeAgreements/common/SendToValidate';
import { openNotification } from 'common/misc/openNotification';
import useUser from 'Domains/TradeAgreements/hooks/useUser';
import usePersistData from 'hooks/usePersistData';
const { ON_PROCESS, REJECTED, APPROVED } = salesChallengeStatus;
const { SALES_AGENT } = roleIdEnum;

function ActionsGroup(props) {
  const {
    clientInfo,
    query,
    clientId,
    territoryId,
    yearSelected,
    process,
    statusClientId,
    setAgreementType: agreementType,
    generateTradeAgreementRequest: generateAgreement,
    isGenerating,
    updatableNational,
    infoData,
    isNational,
    clientCatalogs,
    clientKey,
  } = props;
  const history = useHistory();
  const user = useUser();
  const existClient = typeof clientId === 'string';
  // eslint-disable-next-line no-unused-vars
  const [queryLocal, setQueryLocal] = usePersistData('query', query);

  const determineClientData = () => {
    if (!clientCatalogs || !clientKey) return false;

    return clientCatalogs.find((eachClient) => eachClient.key === clientKey);
  };
  const getIsUpdatableNational = () => {
    const currentClient = determineClientData();
    return currentClient?.isNational && currentClient?.updatable;
  };
  const updatableNational2 = getIsUpdatableNational();

  useEffect(() => {
    setQueryLocal(query);
  }, [query, setQueryLocal]);

  const actionType = useCallback(() => {
    const clientData2 = determineClientData();

    // Checar datos
    // console.log('@@@@@updatableNational2', updatableNational2);
    // console.log('@@@existClient && sameYear', existClient, sameYear, clientInfo);
    // return actionTradeEnum.GENERATE;

    if (clientInfo || clientData2) {
      const sameYear = parseInt(yearSelected) === process?.VALIDITY_PROCESS?.year;
      if (existClient && sameYear) {
        const { statusId, updatable, challenged, acApproved } = clientInfo ?? clientData2;
        const daysLeftRegister = process?.GENERATE_TRADE_AGREEMENT?.daysLeft;
        const daysLeftAdjusted = process?.TRADE_AGREEMENT_ADJUST?.daysLeft;

        if (updatable || updatableNational || updatableNational2) {
          //nota: para nacionales se toma el estatus que viene en indicators de cliente,este se va persistir  firmado ante Toño
          if (isNational) {
            const nationalCanGenerate =
              statusClientId === null &&
              daysLeftRegister >= 0 &&
              challenged &&
              territoryId !== null;

            const nationalCanUpdate =
              statusClientId === ON_PROCESS && daysLeftRegister >= 0 && territoryId !== null;

            const nationalCanAdjust =
              statusClientId === REJECTED && daysLeftAdjusted >= 0 && territoryId !== null;

            const nationalSalesAgentCanAdjust =
              statusClientId === REJECTED &&
              daysLeftAdjusted >= 0 &&
              territoryId !== null &&
              user?.role?.roleId === roleIdEnum.SALES_AGENT;

            const nationalCanRegister =
              !challenged &&
              statusClientId === null &&
              daysLeftRegister >= 0 &&
              territoryId !== null;

            if (nationalCanGenerate) {
              return actionTradeEnum.GENERATE;
            } else if (nationalCanUpdate) {
              return actionTradeEnum.UPDATE;
            } else if (nationalCanAdjust || nationalSalesAgentCanAdjust) {
              return actionTradeEnum.ADJUSTED;
            } else if (nationalCanRegister) {
              return actionTradeEnum.REGISTER;
            }
          } else {
            if (statusId === null && daysLeftRegister >= 0 && challenged) {
              return actionTradeEnum.GENERATE;
            } else if (statusId === ON_PROCESS && daysLeftRegister >= 0) {
              return actionTradeEnum.UPDATE;
            } else if (statusId === REJECTED && daysLeftAdjusted >= 0) {
              return actionTradeEnum.ADJUSTED;
            } else if (!challenged && statusId === null && daysLeftRegister >= 0) {
              //generar acuerdo
              return actionTradeEnum.REGISTER;
            }
          }
        }
        //generar archivo acuerdo
        if (
          statusId === APPROVED &&
          acApproved === true &&
          (user?.role?.roleId === roleIdEnum.ADMINISTRATOR ||
            (user?.role?.roleId === roleIdEnum.AREA_ADMINISTRATOR &&
              user?.area === 'Acceso a mercado'))
        ) {
          return actionTradeEnum.GENERATE_FILE;
        }
      }
      return false;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clientInfo,
    updatableNational,
    updatableNational2,
    yearSelected,
    process,
    existClient,
    user,
    statusClientId,
    territoryId,
    isNational,
  ]);

  const handleActionTrade = useCallback(() => {
    let action = actionType();
    if (action) {
      switch (action.key) {
        case 1:
        case 2:
          agreementType('GENERATE_TRADE_AGREEMENT');
          history.push(action.path);
          break;
        case 3:
          agreementType('TRADE_AGREEMENT_ADJUST');
          history.push(action.path);
          break;
        case 4:
          generateAgreement({
            clientId,
            territoryId: territoryId ? territoryId : null,
            year: yearSelected,
          });
          break;
        case 5:
          agreementType('TRADE_AGREEMENT_GENERATE');
          history.push(action.path);
          break;
        default:
          break;
      }
    }
  }, [actionType, agreementType, history, generateAgreement, clientId, territoryId, yearSelected]);

  const onSuccessValidate = (msg) => {
    openNotification('success', msg);
    setTimeout(() => {
      history.push('/');
    }, 300);
  };

  const handleShowDownloadReport = useCallback(() => {
    const querySet = Object.keys(query).length > 3;
    const data = infoData && infoData.length > 0;
    const nationalValidation = isNational ? query.territoryId : query.clientId;
    const clientValidations = query.clientId ? nationalValidation : true;
    const commonValidity = clientValidations && querySet && data;

    const isSalesAgent = user?.role?.roleId === SALES_AGENT;
    const allApproved = clientInfo?.allApproved;
    const clientStatus = APPROVED === clientInfo?.statusId;

    if (isSalesAgent) {
      return commonValidity && allApproved && clientStatus;
    }
    return commonValidity;
  }, [query, infoData, clientInfo, user, isNational]);

  return (
    <>
      {(user?.role?.roleId === roleIdEnum.ADMINISTRATOR ||
        user?.role?.roleId === roleIdEnum.SALES_AGENT) && (
        <RoleAccessControl moduleKey="trade-agreements" page="register_trade-agreements">
          <>
            {actionType() && (
              <BButton
                onClick={handleActionTrade}
                style={{ margin: '0 .5rem' }}
                type="primary"
                loading={isGenerating}
                label={actionType().label ? actionType().label : 'AA'}
              />
            )}
            {/* Shows 'Enviar acuerdo' btn when agreement can be updated */}
            {existClient && actionType() && actionType()?.key === 2 && (
              <SendToValidate
                pageType="consult"
                onSuccessValidate={onSuccessValidate}
                label="Enviar acuerdo"
              />
            )}
          </>
        </RoleAccessControl>
      )}

      {user?.role?.roleId === roleIdEnum.AREA_ADMINISTRATOR && user?.area === 'Acceso a mercado' && (
        <RoleAccessControl moduleKey="trade-agreements" page="generate_trade-agreements">
          <>
            {actionType() && (
              <BButton
                onClick={handleActionTrade}
                style={{ margin: '0 .5rem' }}
                type="primary"
                loading={isGenerating}
                label={actionType().label ? actionType().label : 'AA'}
              />
            )}

            {existClient && actionType() && actionType()?.key === 2 && (
              <SendToValidate
                pageType="consult"
                onSuccessValidate={onSuccessValidate}
                label="Enviar acuerdo"
              />
            )}
          </>
        </RoleAccessControl>
      )}

      {handleShowDownloadReport() && <DownloadReport />}
    </>
  );
}

function mapStateToProps(state) {
  return {
    query: state.TRADE_AGREEMENTS?.query,
    clientInfo: state?.TRADE_AGREEMENTS?.infoForAgreement?.clientInfo ?? null,
    regionId: state?.TRADE_AGREEMENTS?.query?.regionId ?? null,
    clientId: state?.TRADE_AGREEMENTS?.query?.clientId ?? null,
    territoryId: state?.TRADE_AGREEMENTS?.query?.territoryId ?? null,
    yearSelected: state?.TRADE_AGREEMENTS?.query?.year ?? null,
    process: state?.TRADE_AGREEMENTS?.registerTrade?.daysLeft?.data,
    isGenerating: state?.TRADE_AGREEMENTS?.agreement?.validateStatus?.isLoading ?? false,
    statusClientId: state?.TRADE_AGREEMENTS?.infoForAgreement?.clientInfo?.statusId ?? null,
    isNational: state?.TRADE_AGREEMENTS?.infoForAgreement?.clientInfo?.isNational,
    updatableNational: state?.TRADE_AGREEMENTS?.views?.indicators?.data?.updatable ?? false,
    infoData: state?.TRADE_AGREEMENTS?.views?.info?.data,
    clientCatalogs: state?.APP?.catalogs?.clients?.data ?? false,
    clientKey: state?.TRADE_AGREEMENTS?.clientKey?.clientKey ?? null,
  };
}

export default connect(mapStateToProps, { setAgreementType, generateTradeAgreementRequest })(
  ActionsGroup
);
