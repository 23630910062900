import React, { useEffect, useState, useContext } from 'react';
import { Form, Row, Col, Modal } from 'antd';
import { BInput } from 'common/components';
import { ActionButtons, openNotification, FormTitleDivider } from 'common';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import {
  getTypeClientRequest,
  getBusinessLinesRequest,
  getSegmentationRequest,
  getCompanyRequest,
  getTerritoresRequest,
  getPogsRequest,
  getCurrenciesRequest,
  getPlacementsRequest,
  postClientRequest,
  getResponsableRequest,
  readOneClientRequest,
  getResponsablePositionRequest,
  statusClientRequest,
} from '../redux-saga/actions';
import getRootModule from 'RootModule';
import { Manager, Signer, SignerBASF, Client, SalesInfo, Contact } from '../components';
import clientModule from '../clientModule';
import { useHistory } from 'react-router-dom';
import { layoutContext } from 'context';
import { validaForm, onSubmit } from '../utils';

const GetClient = (props) => {
  const {
    getClientData,
    clientData,
    getPlacementsRequest: getPlacements,
    getTypeClientRequest: getTypesClient,
    getBusinessLinesRequest: getBussinesLines,
    getSegmentationRequest: getSegmentation,
    getCompanyRequest: getCompany,
    getTerritoresRequest: getTerritories,
    getPogsRequest: getPogs,
    getCurrenciesRequest: getCurrencies,
    postClientRequest: registerClient,
    getResponsableRequest: getResponsable,
    readOneClientRequest: getOneClient,
    getResponsablePositionRequest: getResponsablePosition,
    statusClientRequest: changeStatusClient,
  } = props;

  const { onChangeActiveComponent } = useContext(layoutContext);
  const [form] = Form.useForm();
  const [value, SetValue] = useState([]);
  const [isTradeAgreement, setIsTradeAgreement] = useState(false);
  const [isNacional, setIsNacional] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isActiveRegister, setIsActiveRegister] = useState(true);
  const [statusSwitch, setStatusSwitch] = useState({
    //disabled
    checked: false,
    disabled: false,
  });

  const history = useHistory();
  const { state } = history.location;
  const { clientId } = state;
  useEffect(() => {
    getOneClient({ clientId });
    getTypesClient();
    getBussinesLines();
    getSegmentation();
    getCompany();
    getPogs();
    getCurrencies();
    getPlacements();
    onChangeActiveComponent('Users');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.resetFields();
    let arrayValue = [];
    let territoryIdAndRvId = {};
    //let contactAccountStatement = {};
    if (clientData) {
      /*
      if (clientData?.contactAccountStatement?.length > 0) {
        // eslint-disable-next-line array-callback-return
        clientData.contactAccountStatement.map((d, index) => {
          contactAccountStatement[`contact${index + 1}`] = d.name;
          contactAccountStatement[`email${index + 1}`] = d.email;
        });
        form.setFieldsValue(contactAccountStatement);
      }
*/
      if (clientData?.clientManagers?.length > 1) {
        // eslint-disable-next-line array-callback-return
        clientData.clientManagers.slice(1, clientData?.clientManagers?.length).map((d, index) => {
          arrayValue.push(index);
          territoryIdAndRvId[`territoryId${index}`] = d.territoryId;
          territoryIdAndRvId[`respresentativeId${index}`] = d.userId;
          getResponsablePosition({ TerritoryId: d.territoryId, position: index });
        });
        form.setFieldsValue(territoryIdAndRvId);
        if (clientData?.clientManagers[0]?.territoryId !== undefined)
          getResponsable({ TerritoryId: clientData?.clientManagers[0]?.territoryId });
        SetValue(arrayValue);
      } else {
        if (clientData?.clientManagers[0]?.territoryId !== undefined)
          getResponsable({ TerritoryId: clientData?.clientManagers[0]?.territoryId });
      }

      if (clientData?.presenceId === 3) {
        setIsNacional(true);
      } else {
        setIsNacional(false);
      }
      setIsTradeAgreement(!clientData?.tradeAgreementRequired);
      setStatusSwitch({
        disabled: !clientData?.editable,
        checked: !clientData?.enabled,
      });
      setIsActiveRegister(!clientData?.enabled);
      form.setFieldsValue({
        soldTo: clientData?.soldTo,
        name: clientData?.name,
        termTime: clientData?.timeLimit,
        creditLimit: clientData?.creditLimit,
        placementId: clientData?.presenceId,
        typeId: clientData?.typeId,
        lineId: clientData?.lineId,
        segmentationId: clientData?.segmentationId,
        tradeAgreement: clientData?.tradeAgreementRequired,
        imageFile: clientData?.imagen,
        agreementRequirement:
          clientData?.agreementRequirement !== 0 ? clientData?.agreementRequirement : undefined,
        address: clientData?.address,
        companyId: clientData?.companyId,
        ownerName: clientData?.owner,
        territoryId: clientData?.clientManagers[0]?.territoryId,
        respresentativeId: clientData?.clientManagers[0]?.userId,
        //clientManagers
        fullNameClient1: clientData?.clientSigners[0]?.fullName,
        positionClient1: clientData?.clientSigners[0]?.position,
        fullNameClient2: clientData?.clientSigners[1]?.fullName,
        positionClient2: clientData?.clientSigners[1]?.position,
        fullName1: clientData?.basfSigners[0]?.fullName,
        position1: clientData?.basfSigners[0]?.position,
        fullName2: clientData?.basfSigners[1]?.fullName,
        position2: clientData?.basfSigners[1]?.position,
        fullName3: clientData?.basfSigners[2]?.fullName,
        position3: clientData?.basfSigners[2]?.position,
        fullName4: clientData?.basfSigners[3]?.fullName,
        position4: clientData?.basfSigners[3]?.position,
        fullName: clientData?.contact?.fullName,
        departament: clientData?.contact?.department,
        email: clientData?.contact?.email,
        branches: clientData?.salesInfo?.branchesCount,
        clients: clientData?.salesInfo?.clientsCount,
        salesForce: clientData?.salesInfo?.salesForceCount,
        technicalRepresentatives: clientData?.salesInfo?.techRepCount,
        totalInvoicingPreviousYear: clientData?.salesInfo?.totalYearIncome,
        billingCurrency: clientData?.salesInfo?.invoicingCurrencyId,
        tradeAgreementCurrency: clientData?.salesInfo?.agreementCurrencyId,
        icotermType: clientData?.salesInfo?.incoterm,
        pogId: clientData?.salesInfo?.pogTypeId,
      });
      let idLine = clientData?.lineId;
      if (idLine) getTerritories(idLine);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData]);

  //Thousands separator with ","

  const onCancel = () => {
    for (let i = 0; i < 20; i++) {
      localStorage.removeItem(i);
    }
    history.push('/catalog/clients');
  };

  const onValuesChange = (changedValues) => {
    const allValues = form.getFieldsValue();
    validaForm(0, form, value, setIsActiveRegister);
    const currentChange = Object.keys(changedValues)[0];
    switch (currentChange) {
      case 'tradeAgreement':
        if (allValues.tradeAgreement) {
          setIsTradeAgreement(false);
        } else setIsTradeAgreement(true);
        break;
      case 'placementId':
        if (allValues.placementId === 3) {
          setIsNacional(true);
        } else setIsNacional(false);
        SetValue([]);
        break;
      case 'territoryId':
        form.setFieldsValue({
          respresentativeId: undefined,
        });
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line no-shadow
  const handleStatusUpdate = (val) => {
    const onSuccess = (msg) => {
      openNotification('success', msg);
    };
    const errorMessage = (msg) => {
      openNotification('error', msg);
    };
    const actions = {
      true: 'desactivar',
      false: 'activar',
    };
    const message = {
      default: `¿Está seguro que desea ${actions[val]} el cliente?`,
      representative: `¿Está seguro que desea ${actions[val]} el cliente?`,
    };

    Modal.confirm({
      title: 'Confirmar acción',
      content: val === true ? message.representative : message.default,
      onOk() {
        if (statusSwitch.checked) {
          changeStatusClient({
            clientId,
            enable: true,
            setStatusSwitch,
            onSuccess,
            errorMessage,
            history,
          });
          setIsActiveRegister(false);
        } else {
          changeStatusClient({
            clientId,
            enable: false,
            setStatusSwitch,
            onSuccess,
            errorMessage,
            history,
          });
          setIsActiveRegister(true);
        }
      },
    });
  };

  return (
    <>
      <div className={'module-card'}>
        <Form form={form} onValuesChange={onValuesChange}>
          <Row>
            <FormTitleDivider title={'Información de Cliente'} />
          </Row>
          <Client
            getClientData={getClientData}
            clientData={clientData}
            statusSwitch={statusSwitch.checked}
            form={form}
            onValuesChange={onValuesChange}
          />
          <Row>
            <FormTitleDivider title={'Información de responsable'} />
          </Row>
          <Manager
            getClientData={getClientData}
            clientData={clientData}
            validaForm={validaForm}
            value={value}
            SetValue={SetValue}
            isNacional={isNacional}
            setIsActiveRegister={setIsActiveRegister}
            form={form}
            statusSwitch={statusSwitch.checked}
          />
          <Row>
            <FormTitleDivider title={'Información del representante'} />
          </Row>
          <Row>
            <Col span={8}>
              <BInput
                isRequired
                disabled={statusSwitch.checked}
                className="form-add-user-input"
                name="ownerName"
                maxLength={100}
                placeholder="Escribe nombre"
                label="Nombre del propietario o gerente:"
              />
            </Col>
          </Row>
          <Row>
            <FormTitleDivider title={'Información de firmantes cliente'} />
          </Row>
          <Signer clientData={clientData} statusSwitch={statusSwitch.checked} />
          <Row>
            <FormTitleDivider title={'Información de firmantes BASF'} />
          </Row>
          <SignerBASF clientData={clientData} statusSwitch={statusSwitch.checked} />
          <Row>
            <FormTitleDivider title={'Información de contacto comercial del cliente'} />
          </Row>
          <Contact clientData={clientData} statusSwitch={statusSwitch.checked} />
          <Row>
            <FormTitleDivider title={'Información de Ventas'} />
          </Row>
          <SalesInfo
            getClientData={getClientData}
            isTradeAgreement={isTradeAgreement}
            form={form}
            clientData={clientData}
            statusSwitch={statusSwitch.checked}
          />
          {/*    <Row>
            <FormTitleDivider title={'Información de contactos de estado de cuenta'} />
          </Row>
          <ContactAccountStatus statusSwitch={statusSwitch.checked} /> */}
        </Form>
      </div>
      <ActionButtons
        onCancel={onCancel}
        onSubmit={() => onSubmit(history, form, value, clientId, registerClient)}
        statusSwitch={statusSwitch}
        statusChange={handleStatusUpdate}
        disabled={isActiveRegister}
        buttons={['cancel', 'submit']}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    clientData: state?.GETCLIENT?.client?.data,
    getClientData: state.GETCLIENT.client,
  };
}

const ConnectedGetClientForm = connect(mapStateToProps, {
  getTypeClientRequest,
  getBusinessLinesRequest,
  getSegmentationRequest,
  getCompanyRequest,
  getTerritoresRequest,
  getPogsRequest,
  getCurrenciesRequest,
  getPlacementsRequest,
  postClientRequest,
  getResponsableRequest,
  readOneClientRequest,
  getResponsablePositionRequest,
  statusClientRequest,
})(GetClient);

const UpdateClients = () => (
  <DynamicModuleLoader modules={[getRootModule(), clientModule()]}>
    <ConnectedGetClientForm />
  </DynamicModuleLoader>
);

export default UpdateClients;
