import request from 'services/request';
import { onlyBase64 } from 'Domains/TradeAgreements/utils';

const AGREEMENTS_API = '/Agreements/Api/v1/';
const AGREEMENTS_FILES = `${AGREEMENTS_API}SellOut/Files/`;

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export async function getFilesTradeAgreements({ params, cancelToken }) {
  return request(`${AGREEMENTS_FILES}Detail`, {
    method: 'GET',
    params,
    cancelToken,
  });
}

export async function uploadTradeAgreementPDF({ data, cancelToken }) {
  let file64 = await toBase64(data.file);
  const { file, ...exclude } = data;
  file64 = onlyBase64(file64);

  if (file64) {
    return request(`${AGREEMENTS_FILES}`, {
      method: 'POST',
      data: { ...exclude, content: file64 },
      cancelToken,
    });
  }
}
// return await uploadBase64Chunks({
//   base64: file64,
//   exclude,
//   endpoint: `/Agreements/Api/v1/SellOut/Files/SaveChunk`,
//   cancelToken,
//   request,
// });

export async function deleteTradeAgreementPDF({ params, cancelToken }) {
  return request(`${AGREEMENTS_FILES}`, {
    method: 'DELETE',
    params,
    cancelToken,
  });
}

export async function getDownloadTrade({ params, cancelToken }) {
  return request(`${AGREEMENTS_FILES}`, {
    method: 'GET',
    params,
    cancelToken,
  });
}

export async function sendTrade({ params, cancelToken }) {
  return request(`${AGREEMENTS_FILES}SendFile`, {
    method: 'PUT',
    data: params,
    cancelToken,
  });
}
