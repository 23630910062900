import React, { useEffect, useState, useContext } from 'react';
import { Form, Row, Col } from 'antd';
import { BInput } from 'common/components';
import { FormTitleDivider, ActionButtons, openNotification } from 'common';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import {
  getTypeClientRequest,
  getBusinessLinesRequest,
  getSegmentationRequest,
  getCompanyRequest,
  getTerritoresRequest,
  getPogsRequest,
  getCurrenciesRequest,
  getPlacementsRequest,
  postClientRequest,
  getResponsableRequest,
  validateClientRequest,
} from '../redux-saga/actions';
import getRootModule from 'RootModule';
import { Manager, Signer, SignerBASF, Client, SalesInfo, Contact } from '../components';
import clientModule from '../clientModule';
import { validaForm, onSubmit } from '../utils';

import { useHistory } from 'react-router-dom';
import { layoutContext } from 'context';

const GetClient = (props) => {
  const {
    getClientData,
    getPlacementsRequest: getPlacements,
    getTypeClientRequest: getTypesClient,
    getBusinessLinesRequest: getBussinesLines,
    getSegmentationRequest: getSegmentation,
    getCompanyRequest: getCompany,
    getTerritoresRequest: getTerritories,
    getPogsRequest: getPogs,
    getCurrenciesRequest: getCurrencies,
    postClientRequest: registerClient,
    getResponsableRequest: getResponsable,
    validateClientRequest: validateClient,
  } = props;

  const { onChangeActiveComponent } = useContext(layoutContext);
  const [form] = Form.useForm();
  const [value, SetValue] = useState([]);
  const [isTradeAgreement, setIsTradeAgreement] = useState(false);
  const [isNacional, setIsNacional] = useState(true);
  const [isActiveRegister, setIsActiveRegister] = useState(true);
  const history = useHistory();
  useEffect(() => {
    getTypesClient();
    getBussinesLines();
    getSegmentation();
    getCompany();
    getPogs();
    getCurrencies();
    getPlacements();
    onChangeActiveComponent('Users');
    for (let i = 0; i < 20; i++) {
      localStorage.removeItem(i);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Thousands separator with ","
  //const formatThous = (num) => `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const onCancel = () => {
    if (value.length > 0) {
      // eslint-disable-next-line array-callback-return
      value.map((d) => {
        localStorage.removeItem(d);
      });
    }

    history.push('/catalog');
  };

  const onSuccess = (message) => {
    openNotification('success', message);
  };
  const errorMessage = (message) => {
    openNotification('error', message);
  };
  const onValuesChange = (changedValues) => {
    const allValues = form.getFieldsValue();
    validaForm(0, form, value, setIsActiveRegister);
    const currentChange = Object.keys(changedValues)[0];
    switch (currentChange) {
      case 'tradeAgreement':
        if (allValues.tradeAgreement) {
          setIsTradeAgreement(false);
        } else setIsTradeAgreement(true);
        break;
      case 'placementId':
        if (allValues.placementId === 3) {
          setIsNacional(false);
        } else setIsNacional(true);

        // eslint-disable-next-line no-case-declarations
        let territory = {};
        for (let i = 0; i < 20; i++) {
          territory[`territoryId${i}`] = undefined;
          territory[`respresentativeId${i}`] = undefined;
          localStorage.removeItem(i);
        }

        form.setFieldsValue(territory);

        // eslint-disable-next-line no-case-declarations
        //let territories = {};
        form.setFieldsValue({
          territoryId: undefined,
          respresentativeId: undefined,
        });
        setIsActiveRegister(true);

        SetValue([]);
        break;
      case 'territoryId':
        getResponsable(allValues.territoryId);
        form.setFieldsValue({
          respresentativeId: undefined,
        });
        break;
      case 'lineId':
        form.setFieldsValue({
          territoryId: undefined,
          respresentativeId: undefined,
        });
        // eslint-disable-next-line no-case-declarations
        let territories = {};
        if (value.length > 0) {
          // eslint-disable-next-line array-callback-return
          value.map((d) => {
            territories[`territoryId${d}`] = undefined;
            territories[`respresentativeId${d}`] = undefined;
          });
        }
        form.setFieldsValue(territories);
        // eslint-disable-next-line no-case-declarations
        let sendParam = allValues.lineId;
        getTerritories(sendParam);
        if (allValues.soldTo && allValues.lineId)
          validateClient({
            soldTo: allValues.soldTo,
            lineId: allValues.lineId,
            onSuccess,
            errorMessage,
          });

        break;
      default:
        break;
    }
  };
  return (
    <>
      <div className={'module-card'}>
        <Form form={form} onValuesChange={onValuesChange}>
          <Row>
            <FormTitleDivider title={'Información de Cliente'} />
          </Row>
          <Client getClientData={getClientData} form={form} onValuesChange={onValuesChange} />
          <Row>
            <FormTitleDivider title={'Información de responsable'} />
          </Row>
          <Manager
            getClientData={getClientData}
            value={value}
            SetValue={SetValue}
            isNacional={isNacional}
            setIsActiveRegister={setIsActiveRegister}
            form={form}
          />
          <Row>
            <FormTitleDivider title={'Información del representante'} />
          </Row>
          <Row>
            <Col span={8}>
              <BInput
                className="form-add-user-input"
                name="ownerName"
                maxLength={100}
                placeholder="Escribe nombre"
                label="*Nombre del propietario o gerente:"
              />
            </Col>
          </Row>
          <Row>
            <FormTitleDivider title={'Información de firmantes cliente'} />
          </Row>
          <Signer />
          <Row>
            <FormTitleDivider title={'Información de firmantes BASF'} />
          </Row>
          <SignerBASF />
          <Row>
            <FormTitleDivider title={'Información de contacto comercial del cliente'} />
          </Row>
          <Contact />
          <Row>
            <FormTitleDivider title={'Información de Ventas'} />
          </Row>
          <SalesInfo
            getClientData={getClientData}
            isTradeAgreement={isTradeAgreement}
            form={form}
          />

          {/*  <Row>
            <FormTitleDivider title={'Información de contactos de estado de cuenta'} />
          </Row> <ContactAccountStatus /> */}
        </Form>
      </div>
      <ActionButtons
        onCancel={onCancel}
        onSubmit={() => onSubmit(form, history, value, registerClient, setIsActiveRegister)}
        //statusSwitch={statusSwitch}
        /*   onStatusUpdate={handleStatusUpdate} */
        disabled={isActiveRegister}
        buttons={['cancel', 'submit']}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    clientData: state?.GETCLIENT?.client?.data,
    getClientData: state.GETCLIENT.client,
  };
}

const ConnectedGetClientForm = connect(mapStateToProps, {
  getTypeClientRequest,
  getBusinessLinesRequest,
  getSegmentationRequest,
  getCompanyRequest,
  getTerritoresRequest,
  getPogsRequest,
  getCurrenciesRequest,
  getPlacementsRequest,
  postClientRequest,
  getResponsableRequest,
  validateClientRequest,
})(GetClient);

const RegisterClients = () => (
  <DynamicModuleLoader modules={[getRootModule(), clientModule()]}>
    <ConnectedGetClientForm />
  </DynamicModuleLoader>
);

export default RegisterClients;
