export const fnActionType = (
  clientInfo,
  clientData2,
  yearSelected,
  existClient,
  updatableNational,
  updatableNational2,
  ON_PROCESS,
  REJECTED,
  APPROVED,
  user,
  isNational,
  statusClientId,
  actionTradeEnum,
  territoryId,
  roleIdEnum,
  process
) => {
  if (clientInfo || clientData2) {
    const sameYear = parseInt(yearSelected) === process?.VALIDITY_PROCESS?.year;
    if (existClient && sameYear) {
      const { statusId, updatable, challenged, acApproved } = clientInfo ?? clientData2;
      const daysLeftRegister = process?.GENERATE_TRADE_AGREEMENT?.daysLeft;
      const daysLeftAdjusted = process?.TRADE_AGREEMENT_ADJUST?.daysLeft;

      if (updatable || updatableNational || updatableNational2) {
        //nota: para nacionales se toma el estatus que viene en indicators de cliente,este se va persistir  firmado ante Toño
        if (isNational) {
          const nationalCanGenerate =
            statusClientId === null && daysLeftRegister >= 0 && challenged && territoryId !== null;

          const nationalCanUpdate =
            statusClientId === ON_PROCESS && daysLeftRegister >= 0 && territoryId !== null;

          const nationalCanAdjust =
            statusClientId === REJECTED && daysLeftAdjusted >= 0 && territoryId !== null;

          const nationalSalesAgentCanAdjust =
            statusClientId === REJECTED &&
            daysLeftAdjusted >= 0 &&
            territoryId !== null &&
            user?.role?.roleId === roleIdEnum.SALES_AGENT;

          const nationalCanRegister =
            !challenged && statusClientId === null && daysLeftRegister >= 0 && territoryId !== null;

          if (nationalCanGenerate) {
            return actionTradeEnum.GENERATE;
          } else if (nationalCanUpdate) {
            return actionTradeEnum.UPDATE;
          } else if (nationalCanAdjust || nationalSalesAgentCanAdjust) {
            return actionTradeEnum.ADJUSTED;
          } else if (nationalCanRegister) {
            return actionTradeEnum.REGISTER;
          }
        } else {
          if (statusId === null && daysLeftRegister >= 0 && challenged) {
            return actionTradeEnum.GENERATE;
          } else if (statusId === ON_PROCESS && daysLeftRegister >= 0) {
            return actionTradeEnum.UPDATE;
          } else if (statusId === REJECTED && daysLeftAdjusted >= 0) {
            return actionTradeEnum.ADJUSTED;
          } else if (!challenged && statusId === null && daysLeftRegister >= 0) {
            //generar acuerdo
            return actionTradeEnum.REGISTER;
          }
        }
      }
      //generar archivo acuerdo
      if (
        statusId === APPROVED &&
        acApproved === true &&
        (user?.role?.roleId === roleIdEnum.ADMINISTRATOR ||
          (user?.role?.roleId === roleIdEnum.AREA_ADMINISTRATOR &&
            user?.area === 'Acceso a mercado'))
      ) {
        return actionTradeEnum.GENERATE_FILE;
      }
    }
    return false;
  }
  return false;
};
