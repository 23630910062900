import React, { useEffect, useRef, useState } from 'react';
import { Select, Form, InputNumber, Radio, Input, Button } from 'antd';
import { BInput } from 'common/components';
import { getPopupContainer } from 'Domains/SalesChallengeRefactor/helpers/helperForms';
import { IconsMap } from 'Layout/utils';
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const Client = ({ getClientData, statusSwitch, form, onValuesChange, clientData }) => {
  const [showRadioBtns, setshowRadioBtns] = useState(false);
  const [showUpload, setshowUpload] = useState(false);
  const [isCrop, setIsCrop] = useState(false);
  // Effect to set agreementRequirement n imageFile on clientData
  useEffect(() => {
    if (clientData) {
      clientData.lineId !== 1 ? setIsCrop(false) : setIsCrop(true);
      if (clientData.tradeAgreementRequired) setshowRadioBtns(true);
      if (clientData?.agreementRequirement !== 0)
        onValuesChange({ agreementRequirement: clientData.agreementRequirement });
      if (clientData.segmentationId === 1 || clientData.segmentationId === 2) {
        setshowUpload(true);
        if (clientData.imagen) {
          onValuesChange({ imageFile: clientData.imagen });
          setuploadOpt({
            label: 'Reemplazar Imagen',
            fileName: clientData.imagen.fileName,
            fileSize: null,
          });
        }
      }
    }
  }, [clientData]);
  // Effect to set agreementRequirement on condition
  useEffect(() => {
    if (
      (showRadioBtns && clientData?.agreementRequirement === 0) ||
      clientData?.agreementRequirement === undefined
    ) {
      form.setFieldsValue({ agreementRequirement: 3 });
      setTimeout(() => {
        onValuesChange({ agreementRequirement: 3 });
      }, 100);
    }
  }, [showRadioBtns]);

  const [uploadOpt, setuploadOpt] = useState({
    label: 'Subir Imagen',
    fileName: undefined,
    fileSize: undefined,
  });
  const [showLimitErorr, setshowLimitErorr] = useState(false);
  const UploadIcon = IconsMap['UploadOutlined'];
  const PaperClipOutlined = IconsMap['PaperClipOutlined'];
  const { Option } = Select;
  const parserFunc = (value) => {
    let clean = value.replace(/\$\s?|(,*)/g, '');
    if (clean && clean.length > 11) {
      let result = '';
      let decimal = clean.indexOf('.');
      if (decimal > 0 && clean.length <= 18) {
        result = clean.substring(0, 18);
      } else {
        result = clean.substring(0, 11);
      }

      return result;
    } else {
      return clean;
    }
  };
  const keyBlockSolTo = (e) => {
    if (e.shiftKey || (e.shiftKey && e.which === 51) || e.key === 'Dead') {
      e.preventDefault();
    }
    if (
      e.keyCode === 69 ||
      e.keyCode === 73 ||
      e.keyCode === 186 ||
      e.keyCode === 187 ||
      e.keyCode === 189 ||
      e.keyCode === 40 ||
      e.keyCode === 107 ||
      e.keyCode === 109 ||
      e.keyCode === 191 ||
      e.keyCode === 192 ||
      e.keyCode === 219 ||
      e.keyCode === 220 ||
      e.keyCode === 221 ||
      e.keyCode === 222 ||
      e.keyCode === 38 ||
      e.keyCode === 40 ||
      e.key === '{' ||
      e.key === '}' ||
      e.key === '+' ||
      e.key === '*' ||
      e.key === '[' ||
      e.key === '.' ||
      e.key === ']' ||
      e.key === '´' ||
      e.key === '/' ||
      e.key === '<' ||
      e.key === '+' ||
      e.key === '´´' ||
      e.key === 'ArrowLeft' ||
      e.key === 'BracketLeft' ||
      e.key === 'BracketRight' ||
      e.key === 'Quote' ||
      e.key === 'Shift' ||
      e.key === 'Dead' ||
      (e.keyCode >= 65 && e.keyCode <= 90) ||
      e.shiftKey ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowUp'
    ) {
      e.preventDefault();
    }
  };
  const keyBlock = (e) => {
    if (e.shiftKey || (e.shiftKey && e.which === 51) || e.key === 'Dead') {
      e.preventDefault();
    }
    if (
      e.keyCode === 69 ||
      e.keyCode === 73 ||
      e.keyCode === 186 ||
      e.keyCode === 187 ||
      e.keyCode === 189 ||
      e.keyCode === 40 ||
      e.keyCode === 107 ||
      e.keyCode === 109 ||
      e.keyCode === 191 ||
      e.keyCode === 192 ||
      e.keyCode === 219 ||
      e.keyCode === 220 ||
      e.keyCode === 221 ||
      e.keyCode === 222 ||
      e.keyCode === 38 ||
      e.keyCode === 40 ||
      e.key === '{' ||
      e.key === '}' ||
      e.key === '+' ||
      e.key === '*' ||
      e.key === '[' ||
      e.key === ']' ||
      e.key === '´' ||
      e.key === '/' ||
      e.key === '<' ||
      e.key === '+' ||
      e.key === '´´' ||
      e.key === 'ArrowLeft' ||
      e.key === 'BracketLeft' ||
      e.key === 'BracketRight' ||
      e.key === 'Quote' ||
      e.key === 'Shift' ||
      e.key === 'Dead' ||
      (e.keyCode >= 65 && e.keyCode <= 90) ||
      e.shiftKey ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowUp'
    ) {
      e.preventDefault();
    }
  };

  const formatFuncVolume = (valueNumber) => {
    let value = valueNumber.toString();

    let format = '';
    let decimal = value.indexOf('.');

    if (decimal > 0) {
      let a = new RegExp(/(^([0-9]*\.[0-9][0-9])$)/gi);

      let onlyTwoDecimals = value.match(a, '$1');

      if (!onlyTwoDecimals) {
        let splitDecimals = value.split('.');
        if (splitDecimals[1]) {
          let sub = splitDecimals[1].substring(0, 2);
          value = `${splitDecimals[0]}.${sub}`;
        }
      }
      if (value.length <= 18) {
        format = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        let val = value.substring(0, 18);
        format = `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    } else {
      if (value.length <= 11) {
        format = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        let val = value.substring(0, 11);
        format = `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    }
    return format;
  };

  const onChangeSegmentation = (option) => {
    if (option && (option.children === 'Platino' || option.children === 'Black')) {
      setshowUpload(true);
      return;
    }
    setshowUpload(false);
  };

  const onChangeRequiereTrade = (option) => {
    if (option && option.children === 'Si') {
      setshowRadioBtns(true);
      form.setFieldsValue({ agreementRequirement: 3 });
      setTimeout(() => {
        onValuesChange({ agreementRequirement: 3 });
      }, 100);
      return;
    }
    setshowRadioBtns(false);
  };

  const fileInputRef = useRef();

  const handleChangeFileInput = async (event) => {
    const imgFile = event.currentTarget.files[0];
    if (imgFile === undefined) return;
    if (imgFile.type !== 'image/png') return;

    const sizeInMB = imgFile.size / 10 ** 6;
    if (sizeInMB > 10) {
      setshowLimitErorr(true);
      return;
    } else setshowLimitErorr(false);
    const fileName = imgFile.name;

    if (imgFile && fileName) {
      form.setFieldsValue({ imageFile: imgFile });
      onValuesChange({ imageFile: imgFile });
      setuploadOpt({
        label: 'Reemplazar Imagen',
        fileName: fileName,
        fileSize: (Math.round(sizeInMB * 100) / 100).toFixed(2),
      });
    } else {
      form.setFieldsValue({ inputFile: undefined });
      onValuesChange({ inputFile: undefined });
      setuploadOpt({
        label: 'Subir Imagen',
        fileName: undefined,
        fileSize: undefined,
      });
    }
  };

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 10 }}>
      <Form.Item name="soldTo" label="* Sold to">
        <InputNumber
          disabled
          style={{ border: '1px solid #d9d9d9' }}
          bordered={false}
          onKeyDown={keyBlockSolTo}
          placeholder="Escribe Sold To"
          max={2147483647}
          maxLength={10}
          min={0}
          stringMode
        />
      </Form.Item>

      <BInput
        disabled={statusSwitch}
        isRequired
        placeholder="Escribe nombre"
        maxLength={100}
        name="name"
        label="Nombre"
      />
      <BInput
        disabled={statusSwitch}
        isRequired
        placeholder="Escribe plazo"
        maxLength={50}
        className="form-add-user-input"
        name="termTime"
        label="Plazo en el sistema"
      />

      <Form.Item name="creditLimit" label="* Límite de crédito" style={{ height: 20 }}>
        <InputNumber
          disabled={statusSwitch}
          formatter={formatFuncVolume}
          parser={parserFunc}
          onKeyDown={keyBlock}
          style={{ border: '1px solid #d9d9d9' }}
          bordered={false}
          placeholder="Escribe límite"
          max={99999999999.99}
          maxLength={18}
          min={0}
          step="0.01"
          stringMode
        />
      </Form.Item>

      <Form.Item name="placementId" label="* Presencia" style={{ height: 20 }}>
        <Select
          getPopupContainer={getPopupContainer}
          disabled={statusSwitch}
          showSearch
          style={{ width: '100%' }}
          placeholder="Seleccionar"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {getClientData.placements &&
            getClientData.placements.map((d) => {
              return (
                <Option key={d.key} value={d.key}>
                  {d.value}
                </Option>
              );
            })}
        </Select>
      </Form.Item>

      <Form.Item name="typeId" label="Tipo">
        <Select
          allowClear
          getPopupContainer={getPopupContainer}
          disabled={statusSwitch}
          showSearch
          style={{ width: '100%' }}
          placeholder="Seleccionar"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {getClientData.typeClient &&
            getClientData.typeClient.map((d) => {
              return (
                <Option key={d.key} value={d.key}>
                  {d.value}
                </Option>
              );
            })}
        </Select>
      </Form.Item>

      <Form.Item name="lineId" label="* Línea de negocio">
        <Select
          getPopupContainer={getPopupContainer}
          disabled
          showSearch
          style={{ width: '100%' }}
          placeholder="Seleccionar"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {getClientData.businessLine &&
            getClientData.businessLine.map((d) => {
              return (
                <Option key={d.key} value={d.key}>
                  {d.value}
                </Option>
              );
            })}
        </Select>
      </Form.Item>

      <Form.Item name="segmentationId" label="Segmentación">
        <Select
          onChange={(value, option) => onChangeSegmentation(option)}
          getPopupContainer={getPopupContainer}
          allowClear
          disabled={statusSwitch}
          showSearch
          style={{ width: '100%' }}
          placeholder="Seleccionar"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {getClientData.segmentation &&
            getClientData.segmentation.map((d) => {
              return (
                <Option key={d.key} value={d.key}>
                  {d.value}
                </Option>
              );
            })}
        </Select>
      </Form.Item>

      <Form.Item name="tradeAgreement" label="* Requiere PDF de acuerdo comercial">
        <Select
          onChange={(value, option) => onChangeRequiereTrade(option)}
          getPopupContainer={getPopupContainer}
          disabled={statusSwitch}
          showSearch
          style={{ width: '100%' }}
          placeholder="Seleccionar"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value={true}>Si</Option>

          <Option value={false}>No</Option>
        </Select>
      </Form.Item>

      {showRadioBtns && (
        <Form.Item name="agreementRequirement" label="* Tipo de acuerdo comercial">
          <Radio.Group style={{ display: 'flex', flexDirection: 'row' }}>
            <Radio disabled value={1}>
              Sell In
            </Radio>
            <Radio disabled value={2}>
              Sell Out
            </Radio>
            <Radio value={3}>Ambos</Radio>
          </Radio.Group>
        </Form.Item>
      )}

      {isCrop && showUpload && showRadioBtns && (
        <div>
          <Form.Item
            tooltip={{
              title:
                'Imagen que será utilizada para presentarse en la sección "Meta estratégica" del acuerdo comercial en su versión PDF.',
              placement: 'bottom',
              overlayStyle: { maxWidth: '500px' },
              icon: (
                <QuestionCircleOutlined
                  style={{ color: '#0078B3', verticalAlign: 'baseline', marginLeft: '1rem' }}
                />
              ),
            }}
            label="*Imagen para archivo PDF del acuerdo comercial"
          >
            <Input.Group compact>
              <p style={{ color: '#707070', width: '100%', fontSize: '12px' }}>
                <InfoCircleOutlined /> Peso máximo 10MB, formato .png .
              </p>
              {showLimitErorr && (
                <p style={{ color: 'red', fontSize: '12px' }}>
                  La imagen seleccionada excede el peso máximo permitido
                </p>
              )}
              {uploadOpt.fileName && (
                <div
                  style={{
                    color: '#1890FF',
                    marginRight: '1rem',
                    fontSize: '12px',
                    marginTop: '5px',
                    fontWeight: '500',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <PaperClipOutlined />
                    <p
                      style={{
                        maxWidth: '100%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        marginRight: '3px',
                      }}
                    >
                      {uploadOpt.fileName}
                    </p>
                    <p style={{ color: '#707070', fontSize: '12px' }}>
                      {uploadOpt.fileSize && `(${uploadOpt.fileSize} MB)`}
                    </p>
                  </div>
                </div>
              )}
              <Form.Item name="imageFile" noStyle>
                <Button onClick={() => fileInputRef.current.click()}>
                  <UploadIcon /> {uploadOpt.label}
                </Button>
              </Form.Item>
              <input
                onChange={handleChangeFileInput}
                ref={fileInputRef}
                type="file"
                accept=".png"
                multiple={false}
                max={1}
                style={{ display: 'none' }}
              />
            </Input.Group>
          </Form.Item>
        </div>
      )}

      <BInput
        disabled={statusSwitch}
        isRequired
        placeholder="Escribe Dirección"
        maxLength={100}
        className="form-add-user-input"
        name="address"
        label="Dirección:"
      />

      <Form.Item name="companyId" label="* Compañía:">
        <Select
          getPopupContainer={getPopupContainer}
          disabled={statusSwitch}
          showSearch
          style={{ width: '100%' }}
          placeholder="Seleccionar"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {getClientData.company &&
            getClientData.company.map((d) => {
              return (
                <Option key={d.key} value={d.key}>
                  {d.value}
                </Option>
              );
            })}
        </Select>
      </Form.Item>
    </div>
  );
};

export default Client;
